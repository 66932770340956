import http from "../http.js";

const userApi = {
  /** 登录 */
  login: function (params) {
    return http({
      apiDomainIndex: 0,
      method: "post",
      url: `/bigScreenLogin`,
      data: params,
      pureparam: true,
    });
  },
  info: function (params) {
    return http({
      apiDomainIndex: 0,
      method: "get",
      url: `/getInfo`,
      data: params,
      pureparam: true,
    });
  },
};

export default userApi;
