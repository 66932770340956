let tokenkey = "bbdtoken";
let usernamekey = "bbdusername";
let pwdkey = "bbdpwd";

export default {
  setToken: function (value) {
    localStorage.setItem(tokenkey, value);
  },
  getToken: function () {
    return localStorage.getItem(tokenkey);
  },
};
