import Vue from "vue";
import VueRouter from "vue-router";
import { Toast } from "vant";
//导入
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import store from "@/store";
import storage from "@/storage";
import MutationType from "@/store/mutation-type.js";
import utils from "@/utils/utils.js";
Vue.use(VueRouter);

//解决编程式路由往同一地址跳转时会报错的情况 貌似不加这个有时候会引起页面卡住
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
//push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};
//replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch((err) => err);
};

const routes = [
  {
    // 主布局
    path: "/",
    redirect: { name: "Login" },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/login/index/index.vue"),
    meta: {
      title: "login",
      // keepAlive: false,
    },
  },
  {
    // preloading
    path: "/home",
    name: "Home",
    component: () => import("@/views/home/index/index.vue"),
    meta: {
      title: "home",
      // keepAlive: false,
    },
  },
  {
    // 主布局
    path: "/datav",
    name: "Datav",
    component: () => import("@/views/home/index/index.vue"),
    meta: {
      title: "项目概览",
      // keepAlive: false,
    },
    children: [
      {
        // 项目概览
        path: "/",
        name: "Overlay",
        component: () => import(`@/views/overlay/index/index.vue`),
        meta: {
          title: "项目概览",
          // keepAlive: false,
        },
      },

      // {
      //   // 劳务实名
      //   path: "labour",
      //   name: "Labour",
      //   component: () => import("@/views/labour/index/index.vue"),
      //   meta: {
      //     title: "劳务实名",
      //     // keepAlive: false,
      //   },
      // },
      // {
      //   // 进度管理
      //   path: "progress",
      //   name: "Progress",
      //   component: () => import("@/views/progress/index/index.vue"),
      //   meta: {
      //     title: "进度管理",
      //     // keepAlive: false,
      //   },
      // },
      // {
      //   // 绿色施工
      //   path: "green",
      //   name: "Green",
      //   component: () => import("@/views/green/index/index.vue"),
      //   meta: {
      //     title: "绿色施工",
      //   },
      // },
      // {
      //   // 质量检查
      //   path: "quality",
      //   name: "Quality",
      //   component: () => import("@/views/quality/index/index.vue"),
      //   meta: {
      //     title: "质量检查",
      //   },
      // },
      // {
      //   // 安全检查
      //   path: "safely",
      //   name: "Safely",
      //   component: () => import("@/views/safely/index/index.vue"),
      //   meta: {
      //     title: "安全检查",
      //   },
      // },
      // {
      //   // 塔吊监控
      //   path: "towermo",
      //   name: "Towermo",
      //   component: () => import("@/views/towermo/index/index.vue"),
      //   meta: {
      //     title: "塔吊监控",
      //   },
      // },
      // {
      //   // 升降机
      //   path: "lift",
      //   name: "Lift",
      //   component: () => import("@/views/lift/index/index.vue"),
      //   meta: {
      //     title: "塔吊监控",
      //   },
      // },
      // {
      //   // 视频中心
      //   path: "video",
      //   name: "Video",
      //   component: () => import("@/views/video/index/index.vue"),
      //   meta: {
      //     title: "视频中心",
      //   },
      // },
      // {
      //   // 高支模
      //   path: "highmold",
      //   name: "Highmold",
      //   component: () => import("@/views/highmold/index/index.vue"),
      //   meta: {
      //     title: "高支模",
      //   },
      // },
      // {
      //   // 基坑监控
      //   path: "towercr",
      //   name: "Towercr",
      //   component: () => import("@/views/towercr/index/index.vue"),
      //   meta: {
      //     title: "基坑监控",
      //   },
      // },
      // {
      //   // 正在建设
      //   path: "wait",
      //   name: "WaitDevelop",
      //   component: () => import("@/views/wait/index/index.vue"),
      //   meta: {
      //     title: "正在建设",
      //   },
      // },
    ],
  },
];

const router = new VueRouter({
  mode: "hash",
  // base: "/bagcloud/",
  // base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  Toast.clear();
  NProgress.start();
  // store.commit(MutationType.CLEAR_REQ_TOKEN); // 取消请求
  let token = store.state.token;
  if (to.name == "login") {
    next();
  }
  if (!token) {
    token = storage.getToken();
    if (token) {
      store.commit(MutationType.SET_TOKEN_MUTATION, token);
      next();
    }
  }
  next();
});
router.afterEach((to, from) => {
  NProgress.done();
});
export default router;
