<template>
  <div class="datahandle">
    <!-- <div
      class="inc-con"
      :style="nodata ? { opacity: 0, height: `0px` } : { opacity: 1 }"
    >
      <slot></slot>
    </div>
    <no-data
      :style="nodata ? { opacity: 1 } : { opacity: 0, height: `0px` }"
    ></no-data> -->
  </div>
</template>

<script>
import Nodata from "@/components/nodata/index/index.vue";
export default {
  name: "DataHandle",
  props: {
    showNodata: {
      type: Boolean,
      default: undefined,
    },
  },
  components: {
    "no-data": Nodata,
  },
  computed: {
    nodata() {
      if (this.showNodata != undefined) {
        if (this.showNodata) {
          return true;
        }
        return this.showNodata;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.datahandle {
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  .inc-con {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
  }
}
</style>
