import Vue from "vue";
const instance = {
  load: function() {
    //判断如果text值为null 就用 "--" 占位
    Vue.directive("plhovalue", function(el, binding) {
      try {
        let { value, arg } = binding;
        let pl = "-";
        if (arg) {
          if (arg == "zero") {
            pl = "0";
          }
        }
        if (value === 0) {
          pl = "0";
        }
        if (value === "") value = pl;
        let temp = value,
          result = temp || pl;
        el.innerText = result;
      } catch (error) {
        el.innerText = "-";
      }
    });
  },
};
export default instance;
