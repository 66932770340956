import axios from "axios";
import { apiDomains } from "@/config/index.js";
import vueapp from "@/main.js";
import store from "@/store";
import router from "@/router";
import MutationType from "@/store/mutation-type.js";
import storage from "@/storage";
axios.interceptors.request.use((config) => {
  config.cancelToken = new axios.CancelToken(function (cancel) {
    store.commit(MutationType.PUSH_REQ_TOKEN, { cancelToken: cancel });
  });
  return config;
});
let httpErrorTimerf = null;
let httpErrorTimeFw = 500;
const http = function (options) {
  let token = store.state.token;
  let projectcode = store.state.projectCode;
  let userName = store.state.userName;
  let pwd = store.state.pwd;
  let headers = options.headers ? options.headers : {}; // header
  if (token) {
    headers.Authorization = token;
  }
  if (projectcode) {
    if (!options.pureparam) {
      //pureparam 代表纯参数 不进行参数扩展
      options.data.projectcode = projectcode;
    }
  }
  if (options.method === "get") {
    options.params = options.data;
    if (projectcode) {
      if (!options.pureparam) {
        options.params.projectcode = projectcode;
      }
    }
  } else {
    if (options.query) {
      options.params = options.data;
    } else if (options.formData === true) {
      let formData = new FormData();
      for (let i in options.data) {
        formData.append(i, options.data[i]);
      }
      if (projectcode) {
        if (!options.pureparam) {
          formData.append(`projectcode`, projectcode);
        }
      }
      options.data = formData;
      headers["Content-Type"] = "multipart/form-data";
    }
  }
  axios.defaults.baseURL = apiDomains[options.apiDomainIndex];
  axios.defaults.headers = headers;
  return new Promise((resolve, reject) => {
    axios(options)
      .then(async (res) => {
        const { code, result, msg } = res.data;
        if (code == 200) {
          resolve(res.data);
        } else {
          if (code == 401) {
            console.log(`code 401`);
            //可以做重新登录
            if (httpErrorTimerf) {
              clearTimeout(httpErrorTimerf);
            }
            httpErrorTimerf = setTimeout(() => {
              vueapp.$message.error(msg);
            }, httpErrorTimeFw);
            router.push({ name: "Login" });
          } else {
            if (code == 500) {
              reject(res.data);
              return;
            }
            if (httpErrorTimerf) {
              clearTimeout(httpErrorTimerf);
            }
            httpErrorTimerf = setTimeout(() => {
              vueapp.$message.error(msg || "异常错误");
            }, httpErrorTimeFw);
          }
        }
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          // 取消了请求后的操作
          console.log(`取消了请求`);
          reject(err);
        } else {
          if (err.response) {
            const { response } = err;
            if (response.status == 404) {
              if (httpErrorTimerf) {
                clearTimeout(httpErrorTimerf);
              }
              httpErrorTimerf = setTimeout(() => {
                vueapp.$message.error("请检查您的网络");
              }, httpErrorTimeFw);
              return;
            }
            if (response.data.code != 200) {
              if (httpErrorTimerf) {
                clearTimeout(httpErrorTimerf);
              }
              httpErrorTimerf = setTimeout(() => {
                vueapp.$message.error(response.data.message);
              }, httpErrorTimeFw);
              return;
            }
          } else {
            console.log(err);
            if (err.code == 500) {
              reject(err.data);
              return;
            }
            if (httpErrorTimerf) {
              clearTimeout(httpErrorTimerf);
            }
            httpErrorTimerf = setTimeout(() => {
              vueapp.$message.error("请检查您的网络");
            }, httpErrorTimeFw);
          }
          reject({
            net_error: true,
            response: err.response,
          });
        }
      });
  });
};

export default http;
