<template>
  <div class="nodata">
    <p class="title">暂无数据</p>
  </div>
</template>

<script>
export default {
  name: "Nodata",
};
</script>

<style lang="scss" scoped>
.nodata {
  position: relative;
  width: 100%;
  height: 100%;
  // background: url(~@/assets/images/main/nodata-bg.png) center no-repeat;
  @include flcoljusali();
  .title {
    font-size: 16px;
    color: rgba($color: #fff, $alpha: 0.65);
  }
}
</style>
