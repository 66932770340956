<template>
  <div class="nav-con">
    <div class="nav-info">
      <img class="bgcv" :src="require('@/assets/images/toptitle.png')" alt="" />
      <!-- <img class="topdata" :src="require('@/assets/images/title.png')" alt="" /> -->
      <span class="topdata">{{ region }}农产品质量安全监管大数据</span>
      <div class="nav-left">
        <div class="date-con">
          <h1>{{ cycle }}</h1>
          <h1>{{ yearMonth }}</h1>
        </div>
        <div class="line"></div>
        <span class="time">{{ time }}</span>
      </div>
      <div class="van-ri">
        <div class="exit-con" @click="screenfullTap" id="full-screen-btn">
          <img
            class="icon"
            v-show="isFullShow"
            :src="require(`@/assets/images/qp.png`)"
            alt=""
          />
          <img
            class="icon"
            v-show="!isFullShow"
            :src="require(`@/assets/images/tcqp.png`)"
            alt=""
          />
        </div>

        <div class="exit-con" @click="handleQuit">
          <img class="icon" :src="require(`@/assets/images/out.png`)" alt="" />
        </div>
      </div>
      <div
        v-if="region == '500151000000' || region == '铜梁区'"
        @click="phenomenaHand"
        class="phenomena"
      >
        <span>气象</span>
      </div>
      <div
        v-if="region == '500151000000' || region == '铜梁区'"
        @click="monitorHand"
        class="monitor"
      >
        <span>监控</span>
      </div>
    </div>

    <a-modal
      title="提示"
      :visible="quitPopShow"
      @ok="handleQuitOk"
      @cancel="handleQuitCancel"
    >
      <p>确定退出？</p>
    </a-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MutationType from "@/store/mutation-type.js";
import { Progress, Tooltip } from "ant-design-vue";
import "ant-design-vue/lib/tooltip/style/index.css"; // 或者 ant-design-vue/lib/button/style/css 加载 css 文件
import "ant-design-vue/lib/progress/style/index.css"; // 或者 ant-design-vue/lib/button/style/css 加载 css 文件
import loginApi from "@/request/api/user.js";
import moment from "moment";

export default {
  name: "NavBar",
  props: {
    list: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  watch: {
    list(newValue, oldValue) {
      console.log(`list watched`);
      this.convertList(newValue);
    },
  },
  components: {
    "a-tooltip": Tooltip,
    "a-progress": Progress,
  },
  data() {
    return {
      leftList: [],
      rightList: [],
      quitPopShow: false,
      isFullShow: true,
      yearMonth: [], //时间
      cycle: moment().format("dddd"),
      time: "",
    };
  },
  computed: {
    ...mapGetters(["region", "userInfo", "userParams", "token"]),
  },
  mounted() {
    // this.convertList(this.list);
    this.getInfo();
    this.yearMonth = moment().format("YYYY-MM-DD");
    setInterval(() => {
      this.time = moment().format("HH:mm:ss");
    }, 100);
  },
  methods: {
    async getInfo() {
      try {
        let info = await loginApi.info();
        this.$store.commit("setUserInfo", info);
        if (info.meshUser) {
          let regionName = info.meshUser.regionName;
          if (
            regionName.indexOf("酉阳") != -1 ||
            regionName.indexOf("石柱") != -1 ||
            regionName.indexOf("秀山") != -1 ||
            regionName.indexOf("彭水") != -1
          ) {
            let name1 = regionName.substring(0, 2);
            let name2 = regionName.substr(-1);
            console.log(name1, name2);
            let region = `${name1}${name2}`;
            this.$store.commit("setRegion", region);
          } else {
            this.$store.commit("setRegion", regionName);
          }
        }
      } catch (error) {
        console.log(error.msg);
      }
    },
    handleQuitCancel() {
      this.quitPopShow = false;
    },
    /** 确定退出 */
    handleQuitOk() {
      this.quitPopShow = false;
      if (document.fullscreenElement) {
        document.exitFullscreen();
        this.isFullShow = true;
      }
      // this.$store.commit("setRegion", null);
      // this.$store.commit("setUserInfo", null);
      this.$store.commit(MutationType.SET_TOKEN_MUTATION, null);
      this.$router.push({
        name: "Login",
      });
    },
    handleQuit() {
      this.quitPopShow = true;
    },
    /** 首字母大写s */
    titleCase(str) {
      let newStr = str.slice(0, 1).toUpperCase() + str.slice(1).toLowerCase();
      return newStr;
    },
    convertList(list) {
      if (list.length == 0) {
        return;
      }
      let relist = [];
      let spitnum = Math.ceil(list.length / 2);
      for (let i = 0, len = list.length; i < len; i += spitnum) {
        relist.push(list.slice(i, i + spitnum));
      }
      if (relist.length > 1) {
        let leftlist = JSON.parse(JSON.stringify(relist[0]));
        let rightlist = JSON.parse(JSON.stringify(relist[1]));
        leftlist.forEach((element) => {
          element.router.name = this.titleCase(element.router.name);
          element.active = false;
        });
        rightlist.forEach((element) => {
          element.router.name = this.titleCase(element.router.name);
          element.active = false;
        });
        if (leftlist.length > rightlist.length) {
          let count = leftlist.length - rightlist.length;
          for (let index = 0; index < count; index++) {
            let citem = {
              id: `nvbarfillitem-${index}`,
              fillempty: true,
              router: {
                name: "",
              },
            };
            rightlist.push(citem);
          }
        }
        this.leftList = leftlist;
        this.rightList = rightlist;
        return;
      }
      this.$toast.fail("菜单栏配置有误,请联系管理员");
    },
    handleFullscreen() {
      if (document.fullscreenElement) {
        document.exitFullscreen();
        return;
      }
      let element = document.getElementsByTagName("body")[0];
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.webkitRequestFullScreen) {
        element.webkitRequestFullScreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else {
        element.msRequestFullscreen();
      }
    },
    handleNavitem(item) {
      let { leftList, rightList } = this;
      this.resetListAllItemActive(leftList);
      this.resetListAllItemActive(rightList);
      item.active = true;
      this.$emit("change", item);
    },
    resetListAllItemActive(list) {
      for (let index = 0; index < list.length; index++) {
        let element = list[index];
        element.active = false;
      }
    },
    screenfullTap() {
      if (this.isFullShow) {
        const html = document.querySelector("html");
        html
          .requestFullscreen()
          .then(() => {
            console.log("进入全屏成功");
            this.isFullShow = false;
          })
          .catch(() => {
            console.log("进入全屏失败");
          });
      } else {
        if (document.fullscreenElement) {
          document.exitFullscreen();
          this.isFullShow = true;
          return;
        }
      }
    },
    //点击气象
    phenomenaHand() {
      console.log(this.userParams);
      let ip = "http://cq-tlwisdom.xzhlwl.cn";
      // let ip="http://47.109.62.235:1106"
      // let ip = "http://192.168.2.14:9529";
      let url = `${ip}/#/datav?params=${this.userParams}`;
      // window.location.href=url
      window.open(url);
    },
    //点击监控
    monitorHand() {
      let ip = "http://cq-tlwisdom.xzhlwl.cn";
      // let ip="http://47.109.62.235:1106"
      let url = `${ip}/#/datav/labour?params=${this.userParams}`;
      window.open(url);
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-con {
  position: relative;
  .nav-info {
    position: relative;
    height: 100px;
    @include flrowjusali(space-between, flex-start);
    width: 100%;
    overflow: hidden;
    .bgcv {
      position: absolute;
      left: 50%;
      // top: -125px;
      transform: translateX(-50%);
      width: 100%;
    }
    .topdata {
      font-family: "ht";
      position: absolute;
      left: 50%;
      top: 13px;
      transform: translateX(-50%);
      font-size: 44px;
      font-weight: bold;
      color: #ffffff;
      background: linear-gradient(0deg, #85aaff 0%, #e6eeff 99.12109375%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .nav-left {
      display: flex;
      flex-direction: row;
      justify-content: center;
      position: absolute;
      left: 20px;
      top: 42%;
      transform: translateY(-58%);
      .date-con {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;

        h1 {
          font-family: "ldt";
          &:nth-child(1) {
            font-size: 18px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #ffffff;
          }
          &:nth-child(2) {
            font-size: 18px;
            font-family: DIN-Medium;
            font-weight: 400;
            color: #ffffff;
          }
        }
      }
      .line {
        width: 1px;
        height: 36px;
        background: #3d9ec3;
        margin-left: 7px;
        margin-right: 7px;
        margin-top: 7px;
      }
      .time {
        font-size: 25px;
        font-family: "digial";
        font-weight: 400;
        color: #ffffff;
        text-shadow: 0px 0px 6px rgba(113, 247, 255, 0.69);
        margin-top: 8px;
      }
    }
    .navicon {
      position: relative;
      img {
        width: 202px;
        height: 43px;
      }
      .navicon-con {
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -60%);
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        img {
          width: 15px;
          height: 14px;
          margin-right: 8px;
        }
      }
    }
    .nav-ri {
      position: absolute;
      right: 20px;
      top: 48px;
      .nav-ri-con {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .van-ri {
    position: absolute;
    top: 40%;
    right: 20px;
    transform: translateY(-60%);
    padding: 15px 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    .exit-con {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      .icon {
        width: 40px;
        height: 40px;
        margin-right: 2px;
      }
      span {
        font-size: 18px;
        font-family: Alibaba PuHuiTi;
        font-weight: 400;
        color: #00d5fe;
      }
    }
    .exit-con:nth-child(1) {
      margin-right: 50px;
    }
  }
  .phenomena {
    position: absolute;
    top: 8px;
    left: 500px;
    span {
      color: #fff;
      font-weight: bold;
      font-size: 30px;
    }
  }
  .monitor {
    position: absolute;
    top: 8px;
    right: 500px;
    span {
      color: #fff;
      font-weight: bold;
      font-size: 30px;
    }
  }
  .dashed-box {
    position: absolute;
    top: -42px;
    right: 53px;

    outline: none;
    // border: 1px dashed #86afd9;
    // border-radius: 4px;
    // background-color: #18293b;
    color: #fff;
    cursor: pointer;
    @include flrowjusali();
  }
}
</style>
