import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import MutationType from "./store/mutation-type.js";
import store from "./store";
import vglobal from "./global.js";
import NProgress from "nprogress"; // 引入nprogress插件
import "nprogress/nprogress.css"; // 这个nprogress样式必须引入
import rem from "@/utils/rem.js";
import DataHandle from "@/components/data-handle/index/index.vue";
import directive from "./directive";
import BBchartcon from "@/components/bbchart-con/index.vue";
import dragVerify from "@/components/drag-verify/index.js";
import Debounce from "@/components/debounce/index.js";
import swiper from "@/components/vue-awesome-swiper/src/index.js";

Vue.use(swiper);
import "swiper/dist/css/swiper.css";

Vue.use(dragVerify);
import {
  Modal,
  Table,
  Card,
  Form,
  Row,
  Col,
  Select,
  DatePicker,
  Button,
  Icon,
  Input,
  Message,
  LocaleProvider,
  ConfigProvider,
  Radio,
  empty,
  badge,
  spin,
} from "ant-design-vue";
//其实就是在按需引入的常用写法中没有调用到Vue.use所执行的自定义指令，
//就不会报ant-portal错误了
// Modal.install(Vue);
// Form.install(Vue);
// Row.install(Vue);
// Col.install(Vue);
Vue.use(Modal);
Vue.use(Table);
Vue.use(Card);
Vue.use(Form);
Vue.use(Row);
Vue.use(Col);
Vue.use(Select);
Vue.use(DatePicker);
Vue.use(Button);
Vue.use(Icon);
Vue.use(Input);
Vue.use(Message);
Vue.use(ConfigProvider);
Vue.use(Radio);
Vue.use(empty);
Vue.use(badge);
Vue.use(spin);
// Vue.component('a-modal', Modal);
// Vue.component('a-table', Table);
// Vue.component('a-card', Card);
// Vue.component('a-form', Form);
// Vue.component(Row);
// Vue.component(Col);
Vue.component("bbchart-con", BBchartcon);
Vue.component("debounce", Debounce);
// rem();
Vue.config.productionTip = false;
Vue.component("data-handle", DataHandle);
let vueapp = new Vue({
  router,
  store,
  render: (h) => h(App),
});
Vue.prototype.$global = vglobal(vueapp);
Vue.prototype.$confirm = Modal.confirm;
Vue.prototype.$info = Modal.info;
Vue.prototype.$success = Modal.success;
Vue.prototype.$error = Modal.error;
Vue.prototype.$warning = Modal.warning;
Vue.prototype.$message = Message;
Vue.prototype.$Radio = Radio;
Vue.prototype.$Select = Select;
vueapp.$mount("#app");
directive.load();
export default vueapp;
