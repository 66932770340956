<template>
  <div class="error">
    <p>加载失败</p>
  </div>
</template>

<script>
export default {
  name: "PreloadingError",
};
</script>

<style lang="scss" scoped>
</style>